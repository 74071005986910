#inputCard{
    
    height: 32.88px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
    padding: 6px;
    padding-top: 9px;
}