
.control {
  position: relative;
}

input,
select {
  width: 100%;
  font-size: 1rem;
}






.btn {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #007bff;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.btn:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn:active {
  color: #fff;
}

.select:not(.is-multiple){
  width: 100% !important;
}


#inputDatePicker{
  max-width: 19vh;

}

.icon_arrow{
  padding-left: 1vh;
  padding-right: 1vh;
  width: 2.5vh;
  height: auto;
  color: black;
}

