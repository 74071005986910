#containerBoxCalendar{
  padding-left: 20.5vh !important;
  background-size: cover;
  
}

#calendar{
  background-color: rgba(255, 255, 255, 0.5); 
  backdrop-filter: blur(5px); 
}


/* Responsive Part */

 @media screen and (max-width: 768px) {
    #container {
        padding: 0px !important;
    }

    #containerBoxCalendar{
      padding-left: 0vh !important;
      background-image: none;
    
    }
  }